body {
    overflow-x: hidden;
}

#sidebar-wrapper {
    min-height: 100vh;
    margin-left: -15rem;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
}
.backgorund {
    color: white !important;
}
.img-fluid.proofThumb {
    max-height: 135px;
    border-radius: 5px;
    display: block;
    margin: 15px 0 0;
}
.img-fluid {
    max-width: 100%;
    height: 50px !important;
}

#sidebar-wrapper .list-group {
    width: 15rem;
}

#page-content-wrapper {
    min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
}

#menu-toggle {
    color: #fff;
    border: 2px solid #fff;
    padding: 5px 7px;
    margin: 0 0px 0 0;
    line-height: 0;
    position: absolute;
    top: 25px;
    z-index: 9;
    left: 15px;
}



.navbar.adminNav {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 15px 10px 65px;
    background-color:#262a35 !important
}
.text-small {
    font-size: 0.9rem;
}

.chat-box {
    height: 510px;
    overflow-y: scroll;
}

.rounded-lg {
    border-radius: 0.5rem;
}

input::placeholder {
    font-size: 0.9rem;
    color: #999;
}
.col-md-4.mx-auto.mt-5.card.shadow-lg.changecolor {
    background-color: black !important;
}
@media (min-width: 768px) {
    #sidebar-wrapper {
        margin-left: 0;
    }

    #page-content-wrapper {
        min-width: 0;
        width: 100%;
        margin-top: 1%;
    }

    #wrapper.toggled #sidebar-wrapper {
        margin-left: -15rem;
    }

    .adminNav .dropdown-menu a {
        display: block;
        color: #000;
        padding: 8px 10px;
    }
}

#as-react-datatable-container select {
    width: 70px !important;
}

.container input {
    position: static !important;
    opacity: 1 !important;
    cursor: pointer !important;
    height: auto !important;
    width: 100% !important;
}

.container [type=checkbox] {
    position: absolute !important;
    opacity: 0 !important;
    cursor: pointer !important;
    height: 0 !important;
    width: 0 !important;
}

.chip-body {
    height: 37px !important
}

.multiSelect {
    margin: 0 !important;
}

.tableHead h2 {
    font-size: 24px;
    color: #d6b032;
    text-align: left;
    margin: 12px 15px 15px;
    font-weight: 600;
}

@media (max-width: 767px) {
    .adminNav .dropdown-menu {
        background: transparent;
        margin-top: 0;
        padding-top: 0;
        border: 0;
    }

    .adminNav .dropdown-menu a {
        display: block;
        color: #fff;
        padding: 10px 0;
    }

    .adminNav .dropdown-menu a:hover {
        color: #d6b032;
        text-decoration: none;
    }

    .adminNav .dropdown-item:hover,
    .adminNav .dropdown-item:focus {
        background: transparent;
        text-decoration: none;
    }

    .adminNav.navbar-dark .navbar-toggler {
        color: #fff;
        border: 2px solid #fff;
        padding: 5px 7px;
        margin: 0 0px 0 0;
        line-height: 0;
        font-size: inherit;
        outline: none;
    }

    .navbar.adminNav {
        position: relative;
        display: flex;
        align-items: center;
        padding: 10px 15px 10px 65px;
    }
}
select.form-control{
    -webkit-appearance: menulist!important;
    -moz-appearance: menulist!important;
    -ms-appearance: menulist!important;
    -o-appearance: menulist!important;
    appearance: menulist!important;
}

.footer_chat {
    position: relative;
}
i.fa-paper-plane {
    position: absolute;
    top: 10px;
    right: 12px;
    color: #0d6efd;
}
.file-field {
    position: absolute;
    top: 6px;
    right: 50px;
    width: auto;
}
.file-field input#file-input {
    display: none;
}
.table thead th {
    padding-right: 30px !important;
    vertical-align: middle;
}